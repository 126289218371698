<template>
  <div id="positions_module">
    <div class="px-4 py-2 align-center d-flex">
      <div class="font-weight-bold primaryColor" id="positions_label">
        Positions
      </div>
      <v-progress-circular
        class="ml-2"
        indeterminate
        v-if="postionLoader"
        size="18"
        :width="2"
        color="blue"
      ></v-progress-circular>
    </div>
    <v-row class="mb-2 mx-0 mt-0 px-4">
      <v-slide-group v-model="infoType" class="pa-0" mandatory hide-arrows>
        <v-slide-item
          v-for="(item, i) in tabList"
          :key="i"
          v-bind:value="item.name"
          v-slot="{ active, toggle }"
        >
          <v-tooltip
            top
            :color="
              $vuetify.theme.dark
                ? 'darktoolTipColor toolTipTop darkTooltipTop'
                : 'lighttoolTipColor toolTipTop lightTooltipTop'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                @click="
                  $router
                    .push({
                      path: 'positions',
                      query: {
                        tab: infoType ? infoType.trim().toString() : 'All',
                      },
                    })
                    .catch(() => {})
                "
              >
                <v-btn
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  :color="active ? 'activeColor white--text' : menuColors()"
                  :id="`${item.name}_btn`"
                  height="26"
                  min-width="50"
                  class="fsize12 mr-2 rounded-sm text-center text-capitalize"
                  :value="item"
                  @click="toggle"
                >
                  {{ item.name }}
                  <span v-if="item.length != 0">
                    &nbsp; ({{ item.length }})</span
                  >
                </v-btn>
              </span>
            </template>
            <span>{{ item.toolTip }}</span>
          </v-tooltip>
        </v-slide-item>
      </v-slide-group>
      <v-spacer></v-spacer>

      <span
        class="float-right d-flex align-center"
        id="searchBarAndIcons"
        v-if="currentTableData.length > 0 && !$store.state.isMobileView"
      >
        <span class="search-box mx-2">
          <span class="searchwhole">
            <customIcon
              :name="'search'"
              class="searchimg"
              :width="'16'"
              :height="'16'"
              :color="$store.state.amogaColor"
            />
            <span class="searchinput">
              <input
                id="positionsearch"
                class="placeholdsearch"
                placeholder="Search"
                autocorrect="off"
                v-model="searchPosition"
                oninput="this.value = this.value.toUpperCase()"
              />
            </span>
          </span>
        </span>

        <!-- settings  -->
        <!-- <div
            class="text-center pa-0 ma-0 pl-2 d-flex justify-end align-center mr-2"
          >
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="300"
              offset-y
              content-class="settingsMenu"
            >
              <template v-slot:activator="{ on, attrs }">
                <span depressed v-bind="attrs" v-on="on"> 
                  <customIcon
                    :name="'settings'"
                    class="mx-1 cursor d-flex"
                    :width="'21'"
                    :height="'21'"
                    :color="$store.state.amogaColor"
                  />
                </span>
              </template>

              <v-card class="pa-2 overflowscroll" min-height="200">
                <v-col class="col-12">
                  <div>
                    <v-card class="addRemoveCard" height="320" width="230">
                      <div class="text-h6">Add/Remove Column</div>
                      <div
                        class="fsize12"
                        v-for="(item, index) in positionHeaderSelection"
                        :key="positionHeaderSelection[index].value"
                      >
                        <v-checkbox
                          class="pa-1"
                          dense
                          :ripple="false"
                          hide-details
                          :label="item.text"
                          v-model="item.selected"
                          :disabled="item.disabled"
                          @change="positionColUpdate"
                        ></v-checkbox>
                      </div>
                    </v-card>
                  </div>
                </v-col>
              </v-card>
            </v-menu>
          </div> -->
        <!-- settings end-->

        <v-tooltip
          top
          :color="
            $vuetify.theme.dark
              ? 'darktoolTipColor toolTipTop darkTooltipTop'
              : 'lighttoolTipColor toolTipTop lightTooltipTop'
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <a id="position">
              <span
                v-bind="attrs"
                v-on="on"
                v-if="!$store.state.isMobileView"
                @click="
                  checkDownload(
                    'postions',
                    'postions',
                    searchPosition ? positionSearchFilter : currentTableData, ''
                  )
                "
                id="positions_download"
              >
                <customIcon
                  :name="'download'"
                  class="mx-1 cursor d-flex"
                  :width="'21'"
                  :height="'21'"
                  :color="$store.state.amogaColor"
                />
              </span>
            </a>
          </template>
          <span>Download</span>
        </v-tooltip>
      </span>
    </v-row>
    <v-divider class="mx-4 mb-2"></v-divider>

    <v-card
      class="ma-4 pa-4"
      v-if="
        ($store.state.isMobileView &&
          currentTableData.length != 0 &&
          this.searchPosition == '' &&
          !this.postionLoader) ||
        (positionSearchFilter.length != 0 &&
          this.searchPosition !== '' &&
          $store.state.isMobileView &&
          !this.postionLoader)
      "
    >
      <div
        class="row ma-0 pa-0 d-flex-align-center justify-space-around fsize14"
        id="day's_pnl_label"
      >
        <div>Day's P&L</div>
        <div id="Net_pnl_label">Net P&L</div>
      </div>
      <div
        class="row ma-0 pa-0 d-flex-align-center justify-space-around fsize14 font-weight-600"
      >
        <div
          id="day's_pnl_val"
          :class="
            parseFloat(totalGroup(currentTableData, 'MtoM')) >= 0
              ? 'positiveColor'
              : 'negativeColor'
          "
        >
          {{ totalGroup(currentTableData, "MtoM") }}
        </div>
        <div
          id="Net_pnl_val"
          :class="
            parseFloat(totalGroup(currentTableData, 'pnl')) >= 0
              ? 'positiveColor'
              : 'negativeColor'
          "
        >
          {{ totalGroup(currentTableData, "pnl") }}
        </div>
      </div>
    </v-card>
    <v-card
      class="ma-4"
      v-if="
        (currentTableData.length != 0 &&
          this.searchPosition == '' &&
          !this.postionLoader) ||
        (positionSearchFilter.length != 0 &&
          this.searchPosition !== '' &&
          !this.postionLoader)
      "
    >
      <v-simple-table
        id="positions_table"
        :class="{ overflowinherit: isDropped }"
        v-if="!$store.state.isMobileView"
      >
        <thead @mouseleave="headerIndex = -1" id="positions_table_thead">
          <template>
            <td
              :id="head.text"
              class="px-4 fsize12 cursor border-bottom-table selectRow"
              :class="head.class"
              style="height: 48px"
              @mouseenter="headerIndex = index"
              v-for="(head, index) in headers"
              :key="index"
              @click="head.sortable ? changeSort(head.value) : ''"
            >
              <span>
                <span v-if="head.text == 'checkBox'">
                  <input
                    type="checkbox"
                    v-model="checkboxSelectAll"
                    :checkecd="selectedCheckbox.length > 0"
                    class="tableCheckBox"
                /></span>
                <span v-else-if="head.text == 'Instrument'" :class="head.align"
                  ><span>{{ head.text }}</span>
                  <span :class="head.subClass"
                    ><span
                      v-if="
                        head.isSort || (head.sortable && headerIndex == index)
                      "
                      ><v-icon small>{{
                        head.isdesc ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon></span
                    ></span
                  >
                </span>
                <span v-else :class="head.align"
                  ><span :class="head.subClass"
                    ><span
                      v-if="
                        head.isSort || (head.sortable && headerIndex == index)
                      "
                      ><v-icon small>{{
                        head.isdesc ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon></span
                    ></span
                  >

                  <span :class="head.isInfo ? 'd-flex' : ''">
                    <span v-if="head.isInfo">
                      <v-tooltip
                        max-width="250"
                        top
                        :color="
                          $vuetify.theme.dark
                            ? 'darktoolTipColor toolTipTop darkTooltipTop'
                            : 'lighttoolTipColor toolTipTop lightTooltipTop'
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ head.text }}
                          </span>
                        </template>
                        <span>{{ head.infoContent }}</span>
                      </v-tooltip>
                    </span>
                    <span v-else>{{ head.text }}</span>
                  </span>
                </span>
              </span>
            </td>
          </template>
        </thead>

        <tbody
          id="positions_table_tbody"
          @mouseleave="
            currentIndex = -1;
            isDropped = false;
          "
        >
          <tr
            id="positions_table_tbody_tr"
            v-for="(item, index) in this.searchPosition == ''
              ? currentTableData
              : positionSearchFilter"
            :key="index"
            class="primaryColor tableRow"
            @mouseenter="
              isDropped = false;
              currentIndex = index;
            "
            :class="[item.Netqty == '0' ? 'row-disabled ' : '']"
          >
            <td class="px-4 py-2" style="min-height: 48px">
              <input
                type="checkbox"
                v-model="selectedCheckbox"
                :value="item"
                :disabled="
                  item.Netqty == '0' ||
                  (item.Netqty !== '0' &&
                    (item.Pcode == 'CO' || item.Pcode == 'BO'))
                "
                class="tableCheckBox"
                :id="`${index}_positions_table_check_box`"
              />
            </td>
            <td
              class="text-center px-4 py-2 tableContent"
              :id="`${index}_positions_table_p_code`"
              v-if="positionHeaderSelection['Pcode']['selected']"
            >
              <button
                class="py-1 px-2 fsize10 rounded-sm"
                :class="
                  item.Pcode.toLowerCase() == 'mis'
                    ? 'misBtn'
                    : item.Pcode.toLowerCase() == 'nrml'
                    ? 'nrmlBtn'
                    : 'cncBtn'
                "
              >
                {{ item.Pcode }}
              </button>
            </td>
            <td
              class="text-left pos-reletive px-4 py-2 tableContent"
              :id="`${index}_positions_table_scrip_name`"
              v-if="positionHeaderSelection['formatScripName']['selected']"
            >
              {{ item.formatScripName }}
              <span
                class="fsize10 secondaryColor"
                :id="`${index}_positions_table_scrip_ex`"
                >{{ item.ex }}</span
              >
              <div
                class="dropdown position-absolute right-0"
                v-if="currentIndex == index"
              >
                <v-tooltip
                  top
                  :color="
                    $vuetify.theme.dark
                      ? 'darktoolTipColor toolTipTop darkTooltipTop'
                      : 'lighttoolTipColor toolTipTop lightTooltipTop'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :id="`${index}_positions_table_opt`"
                      @mouseover="
                        getWindowHeight(`${index}_positions_table_opt`)
                      "
                      @click="isDropped = !isDropped"
                      min-width="50"
                      max-width="50"
                      height="23"
                      outlined
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex align-center justify-center pos-reletive fsize12 text-capitalize border-0075e1 rounded-sm color-0075e1 background-white"
                      >More
                    </v-btn>
                  </template>
                  <span>Options</span>
                </v-tooltip>

                <transition name="slide">
                  <ul
                    :id="`${index}_positions_table_opt_ul`"
                    v-if="currentIndex == index && isDropped"
                    :class="isRotate ? 'bottom25' : ''"
                    class="pa-0 list expectMkWatchMoreList"
                  >
                    <li
                      :id="`${index}_positions_table_opt_li_add`"
                      @click="
                        isDropped = false;
                        callOrderWindow(
                          parseFloat(item.Netqty) < 0 ? 'sell' : 'buy',
                          item,
                          'Add'
                        );
                      "
                      v-if="item.Netqty != '0'"
                      class="primaryColor fsize12 cursor"
                    >
                      Add
                    </li>
                    <li
                      :id="`${index}_positions_table_opt_li_exit`"
                      v-if="item.Netqty != '0'"
                      @click="
                        isDropped = false;
                        callOrderWindow(
                          parseFloat(item.Netqty) < 0 ? 'buy' : 'sell',
                          item,
                          'Exit'
                        );
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Exit
                    </li>
                    <li
                      :id="`${index}_positions_table_opt_li_buy`"
                      v-if="item.Netqty == '0'"
                      @click="
                        isDropped = false;
                        callOrderWindow('buy', item, 'Buy');
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Buy
                    </li>
                    <li
                      :id="`${index}_positions_table_opt_li_sell`"
                      @click="
                        isDropped = false;
                        callOrderWindow('sell', item, 'Sell');
                      "
                      v-if="item.Netqty == '0'"
                      class="primaryColor fsize12 cursor"
                    >
                      Sell
                    </li>
                    <li
                      :id="`${index}_positions_table_opt_li_convert`"
                      v-if="
                        item.Netqty != '0' &&
                        item.Pcode != 'CO' &&
                        item.Pcode != 'BO'
                      "
                      @click="
                        callConvertDialog(item);
                        isDropped = false;
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Convert
                    </li>
                    <li
                      :id="`${index}_positions_table_opt_li_info`"
                      @click="
                        showDialog(item);
                        isDropped = false;
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Info
                    </li>
                    <li
                      :id="`${index}_positions_table_opt_li_chart`"
                      @click="
                        isDropped = false;
                        callTrandingViewChart(item, 'position');
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Chart
                    </li>
                  </ul>
                </transition>
              </div>
            </td>
            <td
              :id="`${index}_positions_table_qty`"
              class="text-right px-4 py-2 tableContent"
              :class="
                parseInt(item.Netqty) > 0
                  ? 'postiveQuantity'
                  : parseInt(item.Netqty) == 0
                  ? 'zeroQuantity'
                  : 'negativeQuantity'
              "
              v-if="positionHeaderSelection['Netqty']['selected']"
            >
              {{ item.Netqty }}
            </td>
            <td
              class="text-right px-4 py-2 tableContent"
              :id="`${index}_positions_table_buy_avg`"
              v-if="positionHeaderSelection['NetBuyavgprcPnl']['selected']"
            >
              {{
                item.ex == "CDS" || item.ex == "BCD"
                  ? ruppesFormat(parseFloat(item.NetBuyavgprcPnl).toFixed(4))
                  : ruppesFormat(parseFloat(item.NetBuyavgprcPnl).toFixed(2))
              }}
            </td>
            <td
              class="text-right px-4 py-2 tableContent"
              :id="`${index}_positions_table_sell_avg`"
              v-if="positionHeaderSelection['NetSellavgprcPnl']['selected']"
            >
              {{
                item.ex == "CDS" || item.ex == "BCD"
                  ? ruppesFormat(parseFloat(item.NetSellavgprcPnl).toFixed(4))
                  : ruppesFormat(parseFloat(item.NetSellavgprcPnl).toFixed(2))
              }}
            </td>
            <td
              class="text-right px-4 py-2 tableContent"
              :id="`${index}_positions_table_ltp`"
              v-if="positionHeaderSelection['ltp']['selected']"
            >
              {{
                item.ex == "CDS" || item.ex == "BCD"
                  ? ruppesFormat(parseFloat(item.ltp).toFixed(4))
                  : ruppesFormat(parseFloat(item.ltp).toFixed(2))
              }}
            </td>
            <td
              :id="`${index}_positions_table_mtom`"
              class="text-right px-4 py-2 tableContent font-weight-600"
              :class="
                parseFloat(item.MtoM) >= 0 ? 'positiveColor' : 'negativeColor'
              "
              v-if="positionHeaderSelection['MtoM']['selected']"
            >
              {{ ruppesFormat(parseFloat(item.MtoM).toFixed(2)) }}
            </td>
            <td
              :id="`${index}_positions_table_pnl`"
              class="text-right px-4 py-2 tableContent font-weight-600"
              :class="
                parseFloat(item.pnl) >= 0 ? 'positiveColor' : 'negativeColor'
              "
              v-if="positionHeaderSelection['pnl']['selected']"
            >
              {{ ruppesFormat(parseFloat(item.pnl).toFixed(2)) }}
            </td>
          </tr>
        </tbody>
        <tfoot id="positions_table_tfoot">
          <tr
            height="50px"
            class="primaryColor tableRow"
            id="positions_table_tfoot_tr"
          >
            <td class="px-4 py-2 tableContent" colspan="3">
              <v-btn
                id="positions_table_tfoot_exit_all_btn"
                v-if="selectedCheckbox.length > 0"
                :color="$store.state.buttonThemeColor"
                depressed
                @click="$store.commit('position/setPositionExitDialog', true)"
                min-width="78"
                height="32"
                class="text-capitalize white--text"
                >{{
                  selectedCheckbox.length == 0
                    ? "Exit"
                    : `Exit (${selectedCheckbox.length})`
                }}</v-btn
              >
            </td>
            <td
              colspan="4"
              class="text-right px-4 py-2 tableContent"
              id="positions_table_tfoot_tot_label"
              v-if="
                positionHeaderSelection['MtoM']['selected'] ||
                positionHeaderSelection['pnl']['selected']
              "
            >
              Total
            </td>
            <td
              colspan="1"
              class="text-right px-4 py-2 tableContent font-weight-600"
              id="positions_table_tfoot_tot_mtom"
              v-if="positionHeaderSelection['MtoM']['selected']"
            >
              <span
                :class="
                  parseFloat(totalGroup(currentTableData, 'MtoM')) >= 0
                    ? 'positiveColor'
                    : 'negativeColor'
                "
                >{{ totalGroup(currentTableData, "MtoM") }}</span
              >
            </td>
            <td
              colspan="1"
              class="text-right px-4 py-2 tableContent font-weight-600"
              id="positions_table_tfoot_tot_pnl"
              v-if="positionHeaderSelection['pnl']['selected']"
            >
              <span
                :class="
                  parseFloat(totalGroup(currentTableData, 'pnl')) >= 0
                    ? 'positiveColor'
                    : 'negativeColor'
                "
                >{{ totalGroup(currentTableData, "pnl") }}</span
              >
            </td>
          </tr>
        </tfoot>
      </v-simple-table>

      <div v-if="$store.state.isMobileView">
        <div
          class="row ma-0 px-3 py-2 border-bottom-mwSearch"
          v-for="(item, index) in this.searchPosition == ''
            ? currentTableData
            : positionSearchFilter"
          :key="index"
          :class="parseInt(item.Netqty) == 0 ? 'row-disabled' : ''"
          @click="callBottomSheet(item)"
          :id="`${index}_positions_mobile_div`"
        >
          <div
            class="row pa-0 ma-0 d-flex align-center justify-space-between w-100"
          >
            <div
              :id="`${index}_positions_mobile_qty`"
              class="fsize12"
              :class="
                parseInt(item.Netqty) > 0
                  ? 'postiveQuantity'
                  : parseInt(item.Netqty) == 0
                  ? 'zeroQuantity'
                  : 'negativeQuantity'
              "
            >
              {{ item.Netqty }}
            </div>
            <div class="fsize12" :id="`${index}_positions_mobile_sell_avg`">
              Sell Avg.
              {{
                item.ex == "CDS" || item.ex == "BCD"
                  ? ruppesFormat(parseFloat(item.NetSellavgprcPnl).toFixed(4))
                  : ruppesFormat(parseFloat(item.NetSellavgprcPnl).toFixed(2))
              }}
            </div>
            <div :id="`${index}_positions_mobile_pcode`">
              <button
                class="py-1 px-2 fsize10 rounded-sm"
                :class="
                  item.Pcode.toLowerCase() == 'mis'
                    ? 'misBtn'
                    : item.Pcode.toLowerCase() == 'nrml'
                    ? 'nrmlBtn'
                    : 'cncBtn'
                "
              >
                {{ item.Pcode }}
              </button>
            </div>
          </div>
          <div
            class="row my-2 mx-0 pa-0 d-flex align-center justify-space-between w-100"
          >
            <div class="fsize14" :id="`${index}_positions_mobile_scrip_name`">
              {{ item.formatScripName }}
            </div>
            <div
              :id="`${index}_positions_mobile_scrip_pnl`"
              class="fsize12"
              :class="
                parseFloat(item.pnl) >= 0 ? 'positiveColor' : 'negativeColor'
              "
            >
              {{ ruppesFormat(parseFloat(item.pnl).toFixed(2)) }}
            </div>
          </div>
          <div
            class="row pa-0 ma-0 d-flex align-center justify-space-between w-100"
          >
            <div
              class="fsize10 secondaryColor"
              :id="`${index}_positions_mobile_ex`"
            >
              {{ item.ex }}
            </div>
            <div class="fsize12" :id="`${index}_positions_mobile_buy_avg`">
              Buy Avg.
              {{
                item.ex == "CDS" || item.ex == "BCD"
                  ? ruppesFormat(parseFloat(item.NetBuyavgprcPnl).toFixed(4))
                  : ruppesFormat(parseFloat(item.NetBuyavgprcPnl).toFixed(2))
              }}
            </div>
            <div class="fsize12" :id="`${index}_positions_mobile_ltp`">
              LTP
              {{
                item.ex == "CDS" || item.ex == "BCD"
                  ? ruppesFormat(parseFloat(item.ltp).toFixed(4))
                  : ruppesFormat(parseFloat(item.ltp).toFixed(2))
              }}
            </div>
          </div>
        </div>
      </div>
    </v-card>

    <div
      class="d-flex flex-column justify-center align-center vh-70"
      v-if="
        (!!currentTableData &&
          currentTableData.length == 0 &&
          this.searchPosition == '' &&
          !this.postionLoader) ||
        (!!currentTableData &&
          positionSearchFilter.length == 0 &&
          this.searchPosition !== '' &&
          !this.postionLoader)
      "
    >
      <div>
        <img
          id="positions_no_data_img"
          src="../assets/images/noData.svg"
          alt=""
          class="w-300 h-170 mb-4"
        />
      </div>
      <div class="primaryColor" id="positions_no_data_content">
        NO POSITIONS FOUND
      </div>
    </div>

    <!-- info dialog -->

    <v-dialog
      v-model="infoDialog"
      width="650px"
      transition="scroll-y-reverse-transition"
    >
      <v-card class="pa-5" id="positions_info_dialog">
        <div
          class="d-flex align-baseline py-3 border-bottom-dark"
          id="positions_info_dialog_pcode"
        >
          <button
            class="rounded-sm py-1 px-2 text-capitalize fsize10"
            v-bind:class="[
              this.postionInfodetails.Pcode == 'NRML' ? 'nrmlBtn' : 'misBtn ',
            ]"
          >
            {{ this.postionInfodetails.Pcode }}
          </button>
          <div class="mx-2 fsize14" id="positions_info_dialog_scrip_name">
            {{ this.postionInfodetails.formatScripName }}
          </div>
          <div class="fsize9" id="positions_info_dialog_scrip_ex">
            {{ this.postionInfodetails["ex"] }}
          </div>
        </div>
        <div class="py-2 border-bottom-dark">
          <div class="row ma-0">
            <div class="col-6 py-4 pr-2 pl-0 border-right">
              <div class="d-flex align-center justify-space-between">
                <div
                  class="fsize12 secondaryColor mb-2"
                  id="positions_info_dialog_scrip_qty"
                >
                  Quantity
                </div>
                <div
                  class="fsize12 primaryColor mb-2"
                  id="positions_info_dialog_qty_val"
                >
                  {{ this.postionInfodetails["Netqty"] }}
                </div>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div
                  class="fsize12 secondaryColor mb-2"
                  id="positions_info_dialog_avg_priuce"
                >
                  Avg.price
                </div>
                <div
                  class="fsize12 primaryColor mb-2"
                  id="positions_info_dialog_avg_price_val"
                >
                  {{ this.postionInfodetails["netbuyamt"] }}
                </div>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div
                  class="fsize12 secondaryColor mb-2"
                  id="positions_info_dialog_ltp"
                >
                  Last Traded Price
                </div>
                <div
                  class="fsize12 primaryColor mb-2"
                  id="positions_info_dialog_ltp_val"
                >
                  {{ this.postionInfodetails["ltp"] }}
                </div>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div
                  class="fsize12 secondaryColor mb-2"
                  id="positions_info_dialog_order_type"
                >
                  Order type
                </div>
                <div
                  class="fsize12 primaryColor mb-2"
                  id="positions_info_dialog_order_type_val"
                >
                  Day
                </div>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div
                  class="fsize12 secondaryColor mb-2"
                  id="positions_info_dialog_product"
                >
                  Product
                </div>
                <div
                  class="fsize12 primaryColor mb-2"
                  id="positions_info_dialog_product_val"
                >
                  {{ this.postionInfodetails["Pcode"] }}
                </div>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div
                  class="fsize12 secondaryColor mb-2"
                  id="positions_info_dialog_valitity"
                >
                  Valitity
                </div>
                <div
                  class="fsize12 primaryColor mb-2"
                  id="positions_info_dialog_valitity_val"
                >
                  {{ this.postionInfodetails["Expdate"] }}
                </div>
              </div>
            </div>
            <div class="col-6 py-4 pr-2 pl-0">
              <div class="py-1 px-4">
                <div
                  class="pl-4 fsize14 primaryColor mb-2"
                  id="positions_info_dialog_buy"
                >
                  Buys
                </div>
                <div class="d-flex justify-space-between">
                  <div
                    class="pl-4 fsize12 secondaryColor mb-2"
                    id="positions_info_dialog_buy_qty"
                  >
                    Qty
                  </div>
                  <div
                    class="width-30 fsize12 primaryColor mb-2"
                    id="positions_info_dialog_buy_qty_val"
                  >
                    {{ this.postionInfodetails["Bqty"] }}
                  </div>
                </div>
                <div class="d-flex justify-space-between">
                  <div
                    class="pl-4 fsize12 secondaryColor mb-2"
                    id="positions_info_dialog_price"
                  >
                    Price
                  </div>
                  <div
                    class="width-30 fsize12 primaryColor mb-2"
                    id="positions_info_dialog_price_val"
                  >
                    {{ this.postionInfodetails["NetBuyavgprcPnl"] }}
                  </div>
                </div>

                <div class="d-flex justify-space-between">
                  <div
                    class="pl-4 fsize12 secondaryColor mb-2"
                    id="positions_info_dialog_buy_value"
                  >
                    Value
                  </div>
                  <div
                    class="width-30 fsize12 primaryColor mb-2"
                    id="positions_info_dialog_net_amt"
                  >
                    {{ this.postionInfodetails["netbuyamt"] }}
                  </div>
                </div>

                <hr class="my-2" />
                <div
                  class="pl-4 fsize14 primaryColor mb-2"
                  id="positions_info_dialog_sells"
                >
                  Sells
                </div>
                <div class="d-flex justify-space-between">
                  <div
                    class="pl-4 fsize12 secondaryColor mb-2"
                    id="positions_info_dialog_sell_qty"
                  >
                    Qty
                  </div>
                  <div class="width-30 fsize12 primaryColor mb-2">
                    {{ this.postionInfodetails["Sqty"] }}
                  </div>
                </div>
                <div class="d-flex justify-space-between">
                  <div
                    class="pl-4 fsize12 secondaryColor mb-2"
                    id="positions_info_dialog_price"
                  >
                    Price
                  </div>
                  <div
                    class="width-30 fsize12 primaryColor mb-2"
                    id="positions_info_dialog_price_val"
                  >
                    {{ this.postionInfodetails["NetSellavgprcPnl"] }}
                  </div>
                </div>

                <div class="d-flex justify-space-between">
                  <div
                    class="pl-4 fsize12 secondaryColor mb-2"
                    id="positions_info_dialog_sell_value"
                  >
                    Value
                  </div>
                  <div
                    class="width-30 fsize12 primaryColor mb-2"
                    id="positions_info_dialog_sell_net_amt"
                  >
                    {{ this.postionInfodetails["netSellamt"] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-4 ma-0 d-flex justify-end">
          <v-btn
            id="position_close_btn"
            depressed
            outlined
            class="fsize14 text-capitalize"
            width="60px"
            height="32px"
            @click="infoDialog = false"
            >Close</v-btn
          >
        </div>
      </v-card>
    </v-dialog>

    <!-- exit dialog popup -->
    <v-dialog v-model="exitPositionDialog" max-width="700px">
      <v-expand-transition>
        <v-card class="pa-5" id="multiple_exit_dialog">
          <div class="pb-4" id="multiple_exit_dialog_label">
            {{
              this.selectedCheckbox.length > 1
                ? "Exit Positions"
                : "Exit Position"
            }}
          </div>
          <div class="">
            <v-simple-table
              class="border-ededed"
              id="multiple_exit_dialog_table"
            >
              <thead id="multiple_exit_dialog_table_thead">
                <tr id="multiple_exit_dialog_table_thead_tr">
                  <th id="multiple_exit_dialog_table_thead_th"></th>
                  <th
                    class="text-left"
                    id="multiple_exit_dialog_table_head_Inst"
                  >
                    <span>Instrument</span>
                  </th>
                  <th
                    class="text-right"
                    id="multiple_exit_dialog_table_head_Qty"
                  >
                    <span>Qty</span>
                  </th>
                  <th
                    class="text-center"
                    id="multiple_exit_dialog_table_head_price"
                  >
                    <span>Price</span>
                  </th>
                  <th
                    class="text-left"
                    id="multiple_exit_dialog_table_head_type"
                  >
                    <span>Type</span>
                  </th>
                  <th
                    class="text-left"
                    id="multiple_exit_dialog_table_head_product"
                  >
                    <span>Product</span>
                  </th>
                  <th
                    class="text-left"
                    id="multiple_exit_dialog_table_head_valitity"
                  >
                    <span>Validity</span>
                  </th>
                </tr>
              </thead>
              <tbody id="multiple_exit_dialog_table_tbody">
                <tr
                  v-for="(item, index) in this.selectedCheckbox"
                  :key="index"
                  :id="`${index}_multiple_exit_dialog_table_tbody_tr`"
                >
                  <td
                    class="text-center"
                    :id="`${index}_multiple_exit_dialog_table_tbody_qty`"
                  >
                    <button
                      class="rounded-sm text-capitalize fsize10 px-3 py-1"
                      :class="item.Netqty > 0 ? 'sellcolor' : 'buycolor'"
                    >
                      {{ item.Netqty > 0 ? "SELL" : "BUY" }}
                    </button>
                  </td>

                  <td
                    class="text-left"
                    :id="`${index}_multiple_exit_dialog_table_tbody_scrip_name`"
                  >
                    {{
                      item.formatScripName == null
                        ? item.Tsym
                        : item.formatScripName
                    }}
                    <span
                      class="fsize10 secondaryColor"
                      :id="`${index}_multiple_exit_dialog_table_tbody_scrip_ex`"
                      >{{ item.Exchange }}</span
                    >
                  </td>

                  <td
                    class="text-right"
                    :id="`${index}_multiple_exit_dialog_table_tbody_netQty`"
                  >
                    <span>{{ Math.abs(item.Netqty) }}</span>
                  </td>

                  <td
                    class="text-center"
                    :id="`${index}_multiple_exit_dialog_table_tbody__`"
                  >
                    <span>--</span>
                  </td>

                  <td
                    class="text-left"
                    :id="`${index}_multiple_exit_dialog_table_tbody_prcType`"
                  >
                    <span>MKT</span>
                  </td>

                  <td
                    class="text-left"
                    :id="`${index}_multiple_exit_dialog_table_tbody_pcode`"
                  >
                    <span>{{ item.Pcode }}</span>
                  </td>
                  <td
                    class="text-left"
                    :id="`${index}_multiple_exit_dialog_table_tbody_valitity`"
                  >
                    <span>DAY</span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div class="text-right pt-4">
            <v-btn
              id="exit_confirm_btn"
              height="32"
              min-width="80"
              depressed
              :loading="exitLoader"
              :disabled="exitLoader"
              :color="$store.state.buttonThemeColor"
              class="fsize14 text-capitalize white--text"
              @click="exitAllPositions()"
              >Confirm</v-btn
            >
            <v-btn
              id="exit_close_btn"
              height="32"
              outlined
              min-width="80"
              depressed
              class="ml-2 secondaryColor fsize14 text-capitalize"
              @click="$store.commit('position/setPositionExitDialog', false)"
            >
              Close
            </v-btn>
          </div>
        </v-card>
      </v-expand-transition>
    </v-dialog>

    <!-- convert dialog box -->
    <v-dialog v-model="positionConvertDialog" width="500px">
      <v-expand-transition>
        <v-card id="position_convert_card">
          <div
            class="pb-2 px-8 pt-4 d-flex align-center justify-space-between"
            id="position_convert_card_scrip_name"
          >
            {{
              convertDialogData.formatScripName == null
                ? convertDialogData.Tsym
                : convertDialogData.formatScripName
            }}
            <!-- <span>Are you sure want to logout?</span> -->
            <button
              type="button"
              @click="positionConvertDialog = false"
              id="position_convert_close_icon"
              class="fsize21 float-right"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="pt-4 px-8 pb-4">
            <v-sheet class="d-flex align-center justify-space-between my-2">
              <div class="d-flex flex-column">
                <p
                  class="secondaryColor mb-1 fsize12"
                  id="position_convert_product"
                >
                  Product
                </p>
                <div
                  class="primaryColor fsize14 mb-0 d-flex align-center"
                  id="position_convert_card_pcode"
                >
                  <div>{{ convertDialogData["Pcode"] }}</div>
                  <div class="px-2 fsize16">
                    <v-icon>mdi-arrow-right-bold</v-icon>
                  </div>
                  <v-radio-group
                    v-model="selectedProd"
                    id="position_convert_swicth_group"
                    class="prodRadio"
                    row
                    v-if="
                      convertDialogData.ex == 'NSE' ||
                      convertDialogData.ex == 'BSE'
                    "
                  >
                    <v-radio
                      :label="prod"
                      :value="prod"
                      :id="`position_convert_swicth_${prod}`"
                      class="fsize14"
                      v-for="(prod, i) of productType"
                      :key="i"
                    ></v-radio>
                  </v-radio-group>
                  <span id="position_convert_swicth_val" v-else>{{
                    selectedProd
                  }}</span>
                </div>
              </div>
              <div class="d-flex flex-column">
                <p
                  class="secondaryColor fsize12 mb-1"
                  id="position_convert_Qty_label"
                >
                  Quantity
                </p>
                <v-text-field
                  id="position_convert_qty_inp"
                  height="32"
                  @input="qtyErr = inputFieldValidator()"
                  :step="Math.abs(minlot)"
                  :min="0"
                  :max="convertDialogData.Netqty"
                  class="body-2 qtyInput"
                  v-model.number="convertQty"
                  outlined
                  type="number"
                  autofocus
                  hide-no-data
                  hide-details
                  solo
                  tile
                  flat
                  dense
                ></v-text-field>
              </div>
            </v-sheet>
            <div class="d-flex justify-space-between pt-4">
              <div class="fsize12 red--text">
                <span v-if="qtyErr">{{ qtyErr }}</span>
              </div>
              <div>
                <v-btn
                  id="positions_convert_btn"
                  height="32"
                  :loading="convertionLoader"
                  :disabled="convertionLoader"
                  min-width="80"
                  depressed
                  :color="$store.state.buttonThemeColor"
                  class="fsize14 text-capitalize white--text"
                  @click="positionConvert()"
                  >Convert</v-btn
                >
                <v-btn
                  id="positions_close_btn"
                  height="32"
                  min-width="80"
                  outlined
                  depressed
                  class="ml-2 fsize14 text-capitalize"
                  @click="
                    $store.commit('position/setPositionConvertDialog', false)
                  "
                >
                  Close
                </v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </v-expand-transition>
    </v-dialog>

    <bottomsheet
      :currentData="currentSheetData"
      v-on:from-child="getChildData"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import commonFunc from "../mixins/commonFunctions";
import window from "../mixins/orderWindow";
import toaster from "../store/Services/errorHandling";
import bottomsheet from "../components/bottomSheet.vue";
import customIcon from "../components/customIcon.vue";
import mtom from "../store/Services/formula";
export default {
  mixins: [commonFunc, window],
  data: () => ({
    searchPosition: "",
    infoDialog: false,
    selectedCheckbox: [],
    postionInfodetails: [],
    convertDialog: false,
    isDropped: false,
    currentIndex: -1,
    convertDialogData: [],
    convertQty: Number,
    qtyErr: "",
    isRotate: true,
    currentSheetData: [],
    lightheaders: [
      {
        text: "checkBox",
        align: "center",
        sortable: false,
        value: "checkBox",
        class: "d-flex align-center lightselectRow",
        align: "d-flex",
      },
      {
        text: "Type",
        align: "start",
        sortable: true,
        value: "Pcode",
        class: "text-center lightselectRow",
        align: "d-flex",
        subClass: "mar-r-25 w-16",
        isSort: false,
        isdesc: true,
        isInfo: false,
      },
      {
        text: "Instrument",
        align: "left",
        value: "formatScripName",
        sortable: true,
        class: "text-left lightselectRow",
        align: "d-flex",
        subClass: "ml-auto w-16",
        isSort: false,
        isdesc: true,
        isInfo: false,
      },
      {
        text: "Qty",
        align: "right",
        value: "Netqty",
        sortable: true,
        class: "text-right lightselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
        isInfo: false,
      },
      {
        text: "Buy Avg",
        align: "right",
        value: "NetBuyavgprcPnl",
        sortable: true,
        class: "text-right lightselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
        isInfo: false,
      },
      {
        text: "Sell Avg",
        align: "right",
        value: "NetSellavgprcPnl",
        sortable: true,
        class: "text-right lightselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
        isInfo: false,
      },
      {
        text: "LTP",
        align: "right",
        value: "ltp",
        sortable: true,
        class: "text-right lightselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
        isInfo: false,
      },
      {
        text: "Day's P&L",
        align: "right",
        value: "MtoM",
        sortable: true,
        class: "text-right lightselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
        isInfo: true,
        infoContent:
          "MTM means market to market, which will be Profit or Loss based on previous closing price of the security you have bought or sold.",
      },
      {
        text: "Net P&L",
        align: "right",
        value: "pnl",
        sortable: true,
        class: "text-right lightselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
        isInfo: true,
        infoContent:
          "P/L will be based on your actual buy/sell price and current market price.",
      },
    ],
    darkheaders: [
      {
        text: "checkBox",
        align: "center",
        sortable: false,
        value: "checkBox",
        class: "d-flex align-center darkselectRow",
        align: "d-flex",
      },
      {
        text: "Type",
        align: "start",
        sortable: true,
        value: "Pcode",
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "mar-r-25 w-16",
        isSort: false,
        isdesc: true,
        isInfo: false,
      },
      {
        text: "Instrument",
        align: "left",
        value: "formatScripName",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "ml-auto w-16",
        isSort: false,
        isdesc: true,
        isInfo: false,
      },
      {
        text: "Qty",
        align: "right",
        value: "Netqty",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
        isInfo: false,
      },
      {
        text: "Buy Avg",
        align: "right",
        value: "NetBuyavgprcPnl",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
        isInfo: false,
      },
      {
        text: "Sell Avg",
        align: "right",
        value: "NetSellavgprcPnl",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
        isInfo: false,
      },
      {
        text: "LTP",
        align: "right",
        value: "ltp",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
        isInfo: false,
      },
      {
        text: "Day's P&L",
        align: "right",
        value: "MtoM",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
        isInfo: true,
        infoContent:
          "MTM means market to market, which will be Profit or Loss based on previous closing price of the security you have bought or sold.",
      },
      {
        text: "Net P&L",
        align: "right",
        value: "pnl",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
        isInfo: true,
        infoContent:
          "P/L will be based on your actual buy/sell price and current market price.",
      },
    ],
    positionHeaderSelection: {
      checkBox: {
        text: "checkBox",
        value: "checkBox",
        selected: true,
        disabled: true,
      },
      Pcode: {
        text: "Type",
        value: "Pcode",
        selected: true,
      },
      formatScripName: {
        text: "Instrument",
        value: "formatScripName",
        selected: true,
      },
      Netqty: {
        text: "Qty",
        value: "Netqty",
        selected: true,
      },
      NetBuyavgprcPnl: {
        text: "Buy Avg",
        value: "NetBuyavgprcPnl",
        selected: true,
      },
      NetSellavgprcPnl: {
        text: "Sell Avg",
        value: "NetSellavgprcPnl",
        selected: true,
      },
      ltp: {
        text: "LTP",
        value: "ltp",
        selected: true,
      },
      MtoM: {
        text: "Day's P&L",
        value: "MtoM",
        selected: true,
      },
      pnl: {
        text: "Net P&L",
        value: "pnl",
        selected: true,
      },
    },
    pagination: {
      sortBy: null,
      descending: false,
    },
    isFooter: true,
    headerIndex: -1,
    selectedProd: "",
    productType: [],
    menu: false,
    headers: [],
  }),
  components: {
    bottomsheet,
    customIcon,
  },

  computed: {
    ...mapState("position", [
      "totalPnl",
      "currentTableData",
      "tabList",
      "postionLoader",
      "positionConvertData",
      "exitLoader",
      "convertionLoader",
      "currentTotalPnl",
      "positionList",
    ]),
    ...mapState("orderWindow", ["minlot"]),
    ...mapGetters("position", [
      "getPositionExitDialog",
      "getPositionConvertDialog",
    ]),
    ...mapGetters('funds',['getIsMtf']),

    positionSearchFilter() {
      return this.currentTableData.filter((post) => {
        return post.Tsym.toLowerCase().includes(
          this.searchPosition.toLowerCase()
        );
      });
    },

    checkboxSelectAll: {
      get: function () {
        var _checked = [];
        this.currentTableData.forEach((item) => {
          if (
            item.Netqty != "0" &&
            item.Pcode !== "CO" &&
            item.Pcode !== "BO"
          ) {
            _checked.push(item);
          }
        });
        return this.currentTableData && _checked.length > 0
          ? this.selectedCheckbox.length == _checked.length
          : [];
      },
      set: function (value) {
        var checked = [];
        if (value) {
          this.currentTableData.forEach((item) => {
            if (
              item.Netqty != "0" &&
              item.Pcode !== "CO" &&
              item.Pcode !== "BO"
            ) {
              checked.push(item);
            }
          });
        }
        this.selectedCheckbox = checked;
      },
    },

    exitPositionDialog: {
      get() {
        return this.getPositionExitDialog;
      },
      set(value) {
        if (!value) {
          this.$store.commit("position/setPositionExitDialog", value);
          return value;
        }
      },
    },

    positionConvertDialog: {
      get() {
        return this.getPositionConvertDialog;
      },
      set(value) {
        if (!value) {
          this.$store.commit("position/setPositionConvertDialog", value);
          return value;
        }
      },
    },

    infoType: {
      get() {
        return this.$store.state.position.infoType;
      },
      set(value) {
        this.$store.commit("position/setCurrentTab", value);
        this.$store.commit("position/setCurrentTableData", value);
        this.selectedCheckbox = [];
        localStorage.setItem("positionTab", JSON.stringify(value));
      },
    },
  },
  created() {
    this.$store.dispatch("position/getPositions");
    this.selectedCheckbox = [];
  },

  methods: {
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
      this.getDataFromApi();
    },
    getDataFromApi() {
      return new Promise((resolve, reject) => {
        const { sortBy, descending } = this.pagination;

        let items = this.currentTableData;

        if (sortBy) {
          items = items.sort((a, b) => {
            const sortA =
              sortBy == "Pcode" || sortBy == "formatScripName"
                ? a[sortBy]
                : parseFloat(a[sortBy]);
            const sortB =
              sortBy == "Pcode" || sortBy == "formatScripName"
                ? b[sortBy]
                : parseFloat(b[sortBy]);

            if (descending) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              return 0;
            } else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              return 0;
            }
          });
        }
        if (this.$vuetify.theme.dark) {
          this.headers.findIndex((idex) => {
            idex.value == sortBy ? (idex.isSort = true) : (idex.isSort = false);
            idex.value == sortBy ? (idex.isdesc = descending) : "";
          });
        } else {
          this.headers.findIndex((idex) => {
            idex.value == sortBy ? (idex.isSort = true) : (idex.isSort = false);
            idex.value == sortBy ? (idex.isdesc = descending) : "";
          });
        }
      });
    },
    getChildData(data) {
      if (data["page"] == "Position") {
        if (
          data["action"] == "buy" ||
          data["action"] == "sell" ||
          data["action"] == "Add" ||
          data["action"] == "Exit"
        ) {
          data["action"] == "Add"
            ? this.callOrderWindow(
                parseFloat(data["item"].Netqty) < 0 ? "sell" : "buy",
                data.item,
                data["action"]
              )
            : data["action"] == "Exit"
            ? this.callOrderWindow(
                parseFloat(data["item"].Netqty) < 0 ? "buy" : "sell",
                data.item,
                data["action"]
              )
            : this.callOrderWindow(
                data["action"],
                data.item,
                data["action"] == "buy" ? "Buy" : "Sell"
              );
        }
        if (data["action"] == "Convert") {
          this.callConvertDialog(data.item);
        }
        if (data["action"] == "info") {
          this.showDialog(data.item);
        }
        if (data["action"] == "squareOff") {
          this.selectedCheckbox.push(data["item"]);
          this.exitAllPositions();
        }
        if (data["action"] == "chart") {
          this.callTrandingViewChart(data.item, "position");
        }
      }
    },
    callBottomSheet(val) {
      this.currentSheetData = [];
      var tempData = {
        where: "Position",
        isOpen: true,
        item: val,
      };
      this.currentSheetData.push(tempData);
    },
    getWindowHeight(id) {
      var offsetHeight =
        this.$store.state.windowHeight -
        document.getElementById(id).getBoundingClientRect().top;
      var dropdownHeight = 125;
      offsetHeight < dropdownHeight
        ? (this.isRotate = true)
        : (this.isRotate = false);
    },
    showDialog(item) {
      this.postionInfodetails = item;
      this.infoDialog = true;
    },
    // call order window
    async callOrderWindow(orderType, value, type) {
      if (type == "Exit" && (value.Pcode == "CO" || value.Pcode == "BO")) {
        toaster.toaster(
          "",
          "primary",
          "Exit BO and CO Order From OrderBook",
          5000
        );
        this.$store.dispatch(
          "header/setTabSelection",
          this.$store.state.isMobileView ? 2 : 1
        );
        this.$router
          .push({ path: "orders", query: { tab: "Pending" } })
          .catch(() => {});
      } else {
        this.callOrderWindowByContract(
          value,
          orderType,
          false,
          "positions",
          false
        );
      }
    },

    async callConvertDialog(value) {
      if (value.ex == "NSE" || value.ex == "BSE") {
        this.selectedProd =
          value.Pcode == "MIS" ? "CNC" : value.Pcode == "CNC" ? "MIS" : "MIS";
        this.productType = this.getIsMtf ? ["MIS", "NRML", "CNC", "MTF"] :  ["MIS", "NRML", "CNC"];
        const index = this.productType.indexOf(value.Pcode);
        if (index != -1) {
          this.productType.splice(index, 1);
        }
      } else {
        this.selectedProd = value.Pcode == "MIS" ? "NRML" : "MIS";
      }

      this.qtyErr = null;
      let data = value;
      let orderType = "buy";
      let isModify = false;
      let page = "convert";
      let isBasket = false;
      await this.$store.dispatch("orderWindow/getContract", {
        data,
        orderType,
        isModify,
        page,
        isBasket,
      });
      this.$store.commit("position/setPositionConvertDialog", await true);
      this.convertDialogData = value;
      this.convertQty = Math.abs(this.convertDialogData.Netqty);
    },

    async exitAllPositions() {
      await this.$store.dispatch(
        "position/exitPosition",
        this.selectedCheckbox
      );
      this.selectedCheckbox = [];
    },

    inputFieldValidator() {
      if (this.convertQty.toString() == "") {
        return "Quantity cannot not be empty";
      } else if (this.convertQty == 0) {
        return "Quantity should not be Zero";
      } else if (this.convertQty % this.minlot != 0) {
        return "Quantity should be multiple of " + this.minlot;
      } else if (this.convertQty > this.convertDialogData.Netqty) {
        return (
          "Quantity should not greater then " + this.convertDialogData.Netqty
        );
      } else {
        return null;
      }
    },

    /**
     * Covert Position
     * @author senthil
     * @since 13/12/21
     */
    async positionConvert() {
      if (!this.qtyErr) {
        await this.$store.dispatch("position/positionConversion", {
          selectedProd: this.selectedProd,
          data: this.convertDialogData,
          qty: this.convertQty,
        });
      }
    },

    listenMwTicks(event) {
      var tickerData = event.detail.storage;
      let token = this.$store.state.wsConnection.validToken;
      var selectedArray = this.positionList.filter((el) => {
        return el.token == token;
      });
      if (token && selectedArray.length > 0) {
        for (let element of selectedArray) {
          var responseFeed = tickerData[element.token];
          element.ltp = responseFeed?.lp ? responseFeed.lp : element.ltp;
          element.l = responseFeed?.l ? responseFeed.l : element.l;
          element.h = responseFeed?.h ? responseFeed.h : element.h;
          element.o = responseFeed?.o ? responseFeed.o : element.o;
          element.volume = responseFeed?.v ? responseFeed.v : element.volume;
          element.MtoM =
            mtom.realisedProfitLoss(element, "mtm") +
            mtom.unRealisedProfitLoss(element, "mtm");
          element.pnl =
            mtom.realisedProfitLoss(element, "pnl") +
            mtom.unRealisedProfitLoss(element, "pnl");
          let index = this.positionList.indexOf(element);
          index > -1 ? (this.positionList[index] = element) : "";
        }
      }
    },

    positionColUpdate() {
      try {
        let headerData = this.$vuetify.theme.dark
          ? this.darkheaders
          : this.lightheaders;
        this.headers = [];
        for (let header of headerData) {
          if (this.positionHeaderSelection[header.value].selected) {
            this.headers.push(header);
          }
        }
      } catch (err) {
      }
    },
  },

  mounted() {
    var localTab = JSON.parse(localStorage.getItem("positionTab"));
    this.$route.query.tab
      ? (this.infoType = this.$route.query.tab)
      : (this.infoType = localTab);
    this.headers = this.$vuetify.theme.dark
      ? this.darkheaders
      : this.lightheaders;
  },
};
</script>

<style>
.settingsMenu {
  top: 126px !important;
  left: 1053px;
}
.addRemoveCard {
  box-shadow: none !important;
}
</style>
